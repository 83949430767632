import './App.css';
import React, { Component } from 'react';
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import logo from './assets/logo.png';
import check from './assets/check.png';
import twitter from './assets/twitter.png';
import discord from './assets/discord.png';
import Footer from './Components/footer';
import Countdown from 'react-countdown';
import $ from "jquery";

const tweet = () => {
	window.open("#");
}

const discrd = () => {
	window.open("#");
}

var Scroll = require('react-scroll');

let signer;
var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;

let account;
let mintAmount = 1;
let valueOfNFTs = 0;
let onlyLeft;
let owner;
let publicSale;
let winningTicketNumber;
let myTicketCount;
let myTickets;
let minimumTicketCount;
let feePercentage;
let lotteryIndex;
let ticketPrice;
let poolBalance;
let maximumTicketCount;
let ticketCount;
let TicketsRemaining;
let endTime;
let marqueeA = [];
let records = [];
let drawCount;

$(window).on('load', function () {
	setTimeout(function () { // allowing 3 secs to fade out loader
		$('.page-loader').fadeOut('slow');
	}, 3500);
});

const renderer = ({ days, hours, minutes, seconds, completed }) => {
	/*	if (completed) {
			// Render a completed state
			return <Completionist />;
		} else {*/
	// Render a countdowns

	if (days == 0 && hours == 0 && minutes == 0 && seconds == 0) {

		window.location.reload(true);
		console.log("Mint Begins");
	}


	return <div class="counterBlock"><div class="days">{days}</div><div class="dots">:</div><div class="days">{hours}</div><div class="dots">:</div><div class="days">{minutes}</div><div class="dots">:</div><div class="sec">{seconds}</div></div>;
	/*	}*/
};

const ABI = [
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "ticketAmount",
				"type": "uint256"
			}
		],
		"name": "buyTickets",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "deposit",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "distributePrize",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "drawLottery",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "manualWinnerAddress",
				"type": "address"
			}
		],
		"name": "manualNormalPrizeDistribute",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_drawCount",
				"type": "uint256"
			}
		],
		"name": "setDrawCount",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_duration",
				"type": "uint256"
			}
		],
		"name": "setDuration",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_endTime",
				"type": "uint256"
			}
		],
		"name": "setendTime",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_lotteryIndex",
				"type": "uint256"
			}
		],
		"name": "setLotteryIndex",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_maximumTicketCount",
				"type": "uint256"
			}
		],
		"name": "setMaximumTicketCount",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_minimumTicketCount",
				"type": "uint256"
			}
		],
		"name": "setMinimumTicketCount",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_taxPercentage",
				"type": "uint256"
			}
		],
		"name": "setTax",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_ticketPrice",
				"type": "uint256"
			}
		],
		"name": "setTicketPrice",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_feewallet",
				"type": "address"
			}
		],
		"name": "setWallets",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "startBuying",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdraw",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "depositedAmounts",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "drawCount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "duration",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "endTime",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "feePercentage",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "feewallet",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getLotteryIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "getRecord",
		"outputs": [
			{
				"components": [
					{
						"internalType": "uint256",
						"name": "drawNumber",
						"type": "uint256"
					},
					{
						"internalType": "address",
						"name": "walletAddress",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "prize",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "winningTicketNumber",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "lotteryIndex",
						"type": "uint256"
					}
				],
				"internalType": "struct BlockchainLottery.Records",
				"name": "",
				"type": "tuple"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getRecords",
		"outputs": [
			{
				"components": [
					{
						"internalType": "uint256",
						"name": "drawNumber",
						"type": "uint256"
					},
					{
						"internalType": "address",
						"name": "walletAddress",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "prize",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "winningTicketNumber",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "lotteryIndex",
						"type": "uint256"
					}
				],
				"internalType": "struct BlockchainLottery.Records[]",
				"name": "",
				"type": "tuple[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getTicketCount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "lotteryIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "maximumTicketCount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "minimumTicketCount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "myTicketCount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "myTickets",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "records",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "drawNumber",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "walletAddress",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "prize",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "winningTicketNumber",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "lotteryIndex",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "roundWinner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "startTime",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "ticketCount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "ticketHolders",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "ticketPrice",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "winner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "winningTicketNumber",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

const address = "0x72Aca5Cd2CC95983ebB82D7130Fd7FFD0eF29bcA";

let contract;

class App extends Component {

	state = {
		walletAddress: "",
		totalSupply: "",
		currentPrice: "",
		nextPrice: "",
		nextSessionAmount: "",
		onlyLeftAmount: "",
		statusError: false,
		statusLoading: false,
		success: false,
		nftMintingAmount: 1,
		totalValue: "",
		presaleValue: "0",
		maxmint: '',
		_public_sale_cost: '',
		_presalesale_cost: '',
		gallery: '',
		_owner: '',
		_navbarOpen: 0,
		_mintBox: 0,
		_max_free_per_wallet: '',
		_freeMinted: '',
		_poolBalance: '',
		_marqueeA: [],

		_winningTicketNumber: '',
		_myTicketCount: '',
		_myTickets: '',
		_minimumTicketCount: '',
		_depositedAmounts: '',
		_drawCount: '',
		_feePercentage: '',
		_lotteryIndex: '',
		_ticketPrice: '',
		_maximumTicketCount: '',
		_ticketCount: '',
		_TicketsRemaining: '',
		_totalTicketPrice: '2000000000000000',
		_fee: '600000000000000',
		_endTime: 0,
		endTime: null,
		hours: '00',
		minutes: '00',
		seconds: '00',
		_changeEndTime: '',
		_changeDuration: ''
	}

	closeNav = () => {
		const navBar = document.querySelector(".littleNav");
		navBar.classList.add("hidden");
	}

	closeNav = async event => {
		event.preventDefault();

		this.setState({ _navbarOpen: 0 });
	}

	async componentDidMount() {

		if (localStorage?.getItem('isWalletConnected') === 'true') {
			try {
				const providerOptions = {
					walletconnect: {
						package: WalletConnectProvider, // required
						options: {
							infuraId: "811915bee3744bd38afcf17ecac0f9a6" // required
						}
					},
					/*coinbasewallet: {
						package: CoinbaseWalletSDK, // Required
						options: {
							appName: "Aterium Universe", // Required
							infuraId: "811915bee3744bd38afcf17ecac0f9a6", // Required
							rpc: "", // Optional if `infuraId` is provided; otherwise it's required
							chainId: 1, // Optional. It defaults to 1 if not provided
							darkMode: true // Optional. Use dark theme, defaults to false
						}
					}*/
				};

				const web3Modal = new Web3Modal({
					network: "mainnet", // optional
					cacheProvider: true, // optional
					providerOptions // required
				});



				const provider = await web3Modal.connect();

				//  Enable session (triggers QR Code modal)
				await provider.enable();

				const web3 = new Web3(provider);
				console.log("provider : " + provider);
				// Subscribe to accounts change
				provider.on("accountsChanged", (accounts) => {
					console.log(accounts);
				});

				// Subscribe to chainId change
				provider.on("chainChanged", (chainId) => {
					console.log(chainId);
				});

				// Subscribe to provider connection
				provider.on("connect", (info) => {
					console.log(info);
					console.log("I'm LOGGED IN");
				});

				// Subscribe to provider disconnection
				provider.on("disconnect", (error) => {
					console.log(error);
				});

				// test if wallet is connected
				if (web3Modal.cachedProvider) {
					// connected now you can get accounts
					console.log("web3Modal.cachedProvider :" + web3Modal.cachedProvider);
					console.log("provider :" + provider);

					const accounts = await web3.eth.getAccounts();

					account = accounts[0];
					this.setState({ walletAddress: account });

					//const signer = (new Web3Provider(window.ethereum).getSigner)();

					contract = new web3.eth.Contract(ABI, address); console.log("contract :" + contract);

					if (provider) {


						//	(async () => {

						if (web3Modal.cachedProvider != "walletconnect" /*&& web3Modal.cachedProvider != "coinbasewallet"*/) {

							const chainId = 1;

							if (window.ethereum.networkVersion !== chainId) {
								try {
									await window.ethereum.request({
										method: 'wallet_switchEthereumChain',
										params: [{ chainId: web3.utils.toHex(chainId) }],
									});
								} catch (err) {
									// This error code indicates that the chain has not been added to MetaMask.
									if (err.code === 4902) {
										await window.ethereum.request({
											method: 'wallet_addEthereumChain',
											params: [
												{
													chainName: 'Ethereum Mainnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
													rpcUrls: ['https://etherscan.io'],

													/*chainName: 'Goerli Test Network',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Goerli Test Network', decimals: 18, symbol: 'GoerliETH' },
													rpcUrls: ['https://goerli.infura.io/v3/'],*/

												},
											],
										});
									}
								}
							}


							try {

								try {
									localStorage.setItem('isWalletConnected', true);
								} catch (ex) {
									console.log(ex)
								}

								owner = await contract.methods.owner().call();
								this.setState({ _owner: owner });

								endTime = await contract.methods.endTime().call();
								this.setState({ _endTime: endTime });
								console.log("endTime : " + endTime);

								feePercentage = await contract.methods.feePercentage().call();
								this.setState({ _feePercentage: feePercentage });
								console.log("feePercentage : " + feePercentage);

								const balanceC = await web3.eth.getBalance(address);
								poolBalance = balanceC * 1 * (100 - feePercentage) / 100000000000000000000;;
								//console.log('Contract Balance:', web3.utils.fromWei(poolBalance, 'ether'), 'BNB');
								console.log('Contract Balance:', poolBalance);
								console.log('balanceC:', balanceC);


								if (isNaN(poolBalance)) {
									console.log('Calculation resulted in NaN.');
									poolBalance = 0;
								}

								this.setState({ _poolBalance: poolBalance });

								this.setState({ endTime: new Date(endTime * 1000) });

								this.interval = setInterval(this.updateCountdown, 1000);



								ticketCount = await contract.methods.ticketCount().call();
								this.setState({ _ticketCount: ticketCount });
								console.log("ticketCount : " + ticketCount);

								minimumTicketCount = await contract.methods.minimumTicketCount().call();
								this.setState({ _minimumTicketCount: minimumTicketCount });
								console.log("minimumTicketCount : " + minimumTicketCount);

								lotteryIndex = await contract.methods.lotteryIndex().call();
								this.setState({ _lotteryIndex: lotteryIndex });
								console.log("lotteryIndex : " + lotteryIndex);

								let y = 0;
								let limit = lotteryIndex;
								for (let r = limit; r > 0; r--) {
									records[y] = await contract.methods.records(r - 1).call();
									marqueeA[y] = [records[y].walletAddress, records[y].prize];
									this.setState({ _marqueeA: marqueeA });
									console.log("records : " + records);
									y++;
								}

								drawCount = await contract.methods.drawCount().call();
								this.setState({ _drawCount: drawCount });
								console.log("drawCount : " + drawCount);

								ticketPrice = await contract.methods.ticketPrice().call();
								this.setState({ _ticketPrice: ticketPrice });
								console.log("ticketPrice : " + ticketPrice);

								winningTicketNumber = await contract.methods.winningTicketNumber().call();
								this.setState({ _winningTicketNumber: winningTicketNumber });
								console.log("winningTicketNumber : " + winningTicketNumber);

								myTicketCount = await contract.methods.myTicketCount(account, lotteryIndex).call();
								this.setState({ _myTicketCount: myTicketCount });
								console.log("myTicketCount : " + myTicketCount);

								maximumTicketCount = await contract.methods.maximumTicketCount().call();
								this.setState({ _maximumTicketCount: maximumTicketCount });
								console.log("maximumTicketCount : " + maximumTicketCount);

								TicketsRemaining = maximumTicketCount - ticketCount;
								this.setState({ _TicketsRemaining: TicketsRemaining });

								for (let x = 0; x < 0; x++) {
									myTickets = await contract.methods.myTickets(account, lotteryIndex,).call();
									this.setState({ _myTickets: myTickets });
									console.log("myTickets : " + myTickets);
								}

							}
							catch (err) {
								console.log("err: " + err);

							}

						} else if (web3Modal.cachedProvider == "walletconnect") {

							const chainId = 1;

							if (WalletConnectProvider.networkVersion !== chainId) {
								try {
									await WalletConnectProvider.request({
										method: 'wallet_switchEthereumChain',
										params: [{ chainId: web3.utils.toHex(chainId) }],
									});
								} catch (err) {
									// This error code indicates that the chain has not been added to MetaMask.
									if (err.code === 4902) {
										await window.ethereum.request({
											method: 'wallet_addEthereumChain',
											params: [
												{
													chainName: 'Ethereum Mainnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
													rpcUrls: ['https://etherscan.io'],

													/*chainName: 'Goerli Test Network',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Goerli Test Network', decimals: 18, symbol: 'GoerliETH' },
													rpcUrls: ['https://goerli.infura.io/v3/'],*/

												},
											],
										});
									}
								}
							}


							try {

								try {
									localStorage.setItem('isWalletConnected', true);
								} catch (ex) {
									console.log(ex)
								}

								owner = await contract.methods.owner().call();
								this.setState({ _owner: owner });

								endTime = await contract.methods.endTime().call();
								this.setState({ _endTime: endTime });
								console.log("endTime : " + endTime);

								feePercentage = await contract.methods.feePercentage().call();
								this.setState({ _feePercentage: feePercentage });
								console.log("feePercentage : " + feePercentage);

								const balanceC = await web3.eth.getBalance(address);
								poolBalance = balanceC * 1 * (100 - feePercentage) / 100000000000000000000;;
								//console.log('Contract Balance:', web3.utils.fromWei(poolBalance, 'ether'), 'BNB');
								console.log('Contract Balance:', poolBalance);
								console.log('balanceC:', balanceC);


								if (isNaN(poolBalance)) {
									console.log('Calculation resulted in NaN.');
									poolBalance = 0;
								}

								this.setState({ _poolBalance: poolBalance });

								this.setState({ endTime: new Date(endTime * 1000) });

								this.interval = setInterval(this.updateCountdown, 1000);



								ticketCount = await contract.methods.ticketCount().call();
								this.setState({ _ticketCount: ticketCount });
								console.log("ticketCount : " + ticketCount);

								minimumTicketCount = await contract.methods.minimumTicketCount().call();
								this.setState({ _minimumTicketCount: minimumTicketCount });
								console.log("minimumTicketCount : " + minimumTicketCount);

								lotteryIndex = await contract.methods.lotteryIndex().call();
								this.setState({ _lotteryIndex: lotteryIndex });
								console.log("lotteryIndex : " + lotteryIndex);

								let y = 0;
								let limit = lotteryIndex;
								for (let r = limit; r > 0; r--) {
									records[y] = await contract.methods.records(r - 1).call();
									marqueeA[y] = [records[y].walletAddress, records[y].prize];
									this.setState({ _marqueeA: marqueeA });
									console.log("records : " + records);
									y++;
								}

								drawCount = await contract.methods.drawCount().call();
								this.setState({ _drawCount: drawCount });
								console.log("drawCount : " + drawCount);

								ticketPrice = await contract.methods.ticketPrice().call();
								this.setState({ _ticketPrice: ticketPrice });
								console.log("ticketPrice : " + ticketPrice);

								winningTicketNumber = await contract.methods.winningTicketNumber().call();
								this.setState({ _winningTicketNumber: winningTicketNumber });
								console.log("winningTicketNumber : " + winningTicketNumber);

								myTicketCount = await contract.methods.myTicketCount(account, lotteryIndex).call();
								this.setState({ _myTicketCount: myTicketCount });
								console.log("myTicketCount : " + myTicketCount);

								maximumTicketCount = await contract.methods.maximumTicketCount().call();
								this.setState({ _maximumTicketCount: maximumTicketCount });
								console.log("maximumTicketCount : " + maximumTicketCount);

								TicketsRemaining = maximumTicketCount - ticketCount;
								this.setState({ _TicketsRemaining: TicketsRemaining });

								for (let x = 0; x < 0; x++) {
									myTickets = await contract.methods.myTickets(account, lotteryIndex,).call();
									this.setState({ _myTickets: myTickets });
									console.log("myTickets : " + myTickets);
								}

							} catch (err) {
								console.log("err: " + err);

							}


						}
						//})();

						//.....................................................................//

						// Legacy providers may only have ethereum.sendAsync
						const chainId = await provider.request({
							method: 'eth_chainId'
						})

					} else {

						// if the provider is not detected, detectEthereumProvider resolves to null
						console.error('Please install a Valid Wallet');
						alert('A valid provider could not be found!');

					}

				}
			} catch (ex) {
				console.log(ex);
			}
		} else {

			console.log("NOT CONNECTED");

		}

	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	onSubmitMinus = async event => {
		event.preventDefault();

		mintAmount = mintAmount - 1;

		if (mintAmount < 1) {
			mintAmount = 1
		}

		if (owner == account) {
			console.log("owner : " + owner)
			onlyLeft = 10000 - ticketCount;

			if (mintAmount > onlyLeft) {
				mintAmount = onlyLeft;
			}

			valueOfNFTs = mintAmount * 0;

			this.setState({ nftMintingAmount: mintAmount });

			let totalTicketPrice = mintAmount * ticketPrice;
			this.setState({ _totalTicketPrice: totalTicketPrice });

			let fee = totalTicketPrice * feePercentage / 100;
			this.setState({ _fee: fee });

		} else {
			if (ticketCount < 10000) {

				onlyLeft = 10000 - ticketCount;

				if (mintAmount > onlyLeft) {
					mintAmount = onlyLeft;
				}
				valueOfNFTs = mintAmount * this.state._ticketPrice;

				this.setState({ nftMintingAmount: mintAmount });

				let totalTicketPrice = mintAmount * ticketPrice;
				this.setState({ _totalTicketPrice: totalTicketPrice });

				let fee = totalTicketPrice * feePercentage / 100;
				this.setState({ _fee: fee });

			}
		}

	}

	onSubmitPlus = async event => {
		event.preventDefault();

		//,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
		mintAmount = mintAmount + 1;

		if (owner == account) {
			onlyLeft = 10000 - ticketCount;

			if (mintAmount > onlyLeft) {
				mintAmount = onlyLeft;
			}

			valueOfNFTs = mintAmount * 0;

			this.setState({ nftMintingAmount: mintAmount });

			let totalTicketPrice = mintAmount * ticketPrice;
			this.setState({ _totalTicketPrice: totalTicketPrice });

			let fee = totalTicketPrice * feePercentage / 100;
			this.setState({ _fee: fee });

		} else {

			if (ticketCount < 10000) {

				onlyLeft = 10000 - ticketCount;
				console.log(onlyLeft);

				if (mintAmount > onlyLeft) {
					mintAmount = onlyLeft;
				}

				valueOfNFTs = mintAmount * this.state._ticketPrice;

				this.setState({ nftMintingAmount: mintAmount });

				let totalTicketPrice = mintAmount * ticketPrice;
				this.setState({ _totalTicketPrice: totalTicketPrice });

				let fee = totalTicketPrice * feePercentage / 100;
				this.setState({ _fee: fee });

			}
		}

	}

	buyTickets = async event => {
		event.preventDefault();

		console.log(this.state.walletAddress);

		try {
			let owner = await contract.methods.owner().call();


			if (account != owner) {


				try {

					console.log("minAmount:" + mintAmount);
					console.log("valueOfNFTs:" + valueOfNFTs);

					this.setState({ statusError: false, statusLoading: true });
					await contract.methods.buyTickets(mintAmount * 1).send({ from: account, value: mintAmount * ticketPrice });

					this.setState({ statusLoading: false, success: true });
					console.log("Mint Amount :" + this.state.mintAmount);
					await new Promise(resolve => setTimeout(resolve, 5000));
					window.location.reload(true);


				} catch (err) {
					this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
					await new Promise(resolve => setTimeout(resolve, 4000));
					window.location.reload(true);
					console.log(err);

				}
			} else {

				try {


					console.log("minAmount:" + mintAmount);
					console.log("valueOfNFTs:" + valueOfNFTs);

					this.setState({ statusError: false, statusLoading: true });
					await contract.methods.buyTickets(mintAmount * 1).send({ /*gasLimit: 385000,*/    from: account, value: 0 });

					this.setState({ statusLoading: false, success: true });
					console.log("Mint Amount :" + this.state.mintAmount);
					await new Promise(resolve => setTimeout(resolve, 5000));
					window.location.reload(true);


				} catch (err) {
					this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
					await new Promise(resolve => setTimeout(resolve, 4000));
					window.location.reload(true);
					console.log(err);

				}

			}
		} catch (err) {

			console.log(err);
		}
	}

	startBuying = async event => {
		event.preventDefault();

		console.log(this.state.walletAddress);

		try {

			await contract.methods.startBuying().send({ from: account });
			window.location.reload(true);

		} catch (err) {

			console.log(err);

		}
	}

	drawLottery = async event => {
		event.preventDefault();

		console.log(this.state.walletAddress);

		try {
			let owner = await contract.methods.owner().call();

			await contract.methods.drawLottery().send({ from: account });
			window.location.reload(true);

		} catch (err) {

			console.log(err);

		}
	}

	changeEndTime = async event => {
		event.preventDefault();
		try {

			await contract.methods.setendTime(this.state._changeEndTime).send({ from: account });
			window.location.reload(true);

		} catch (err) {
			console.log(err);

		}

	}

	changeDuration = async event => {
		event.preventDefault();
		try {

			await contract.methods.setDuration(this.state._changeDuration).send({ from: account });
			window.location.reload(true);

		} catch (err) {
			console.log(err);

		}

	}

	walletConnect = async event => {
		event.preventDefault();

		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "bf933c3446b3464c988114813a1360ac" // required
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});

		const provider = await web3Modal.connect();

		//  Enable session (triggers QR Code modal)
		await provider.enable();

		const web3 = new Web3(provider);
		console.log("provider : " + provider);
		// Subscribe to accounts change
		provider.on("accountsChanged", (accounts) => {
			console.log(accounts);
		});

		// Subscribe to chainId change
		provider.on("chainChanged", (chainId) => {
			console.log(chainId);
		});

		// Subscribe to provider connection
		provider.on("connect", (info) => {
			console.log(info);
		});

		// Subscribe to provider disconnection
		provider.on("disconnect", (error) => {
			console.log(error);
		});

		// test if wallet is connected
		if (web3Modal.cachedProvider) {
			// connected now you can get accounts
			console.log("provider :" + web3Modal.cachedProvider);
			const accounts = await web3.eth.getAccounts();

			account = accounts[0];
			this.setState({ walletAddress: account });
			//const signer = (new Web3Provider(window.ethereum).getSigner)();

			contract = new web3.eth.Contract(ABI, address);
			console.log("contract :" + contract);
			//const provider = new Provider('https://testnet.era.zksync.dev');
			// Note that we still need to get the Metamask signer
			/*const signer = (new Web3Provider(window.ethereum).getSigner)();
			contractZksync = new web3.eth.Contract(
				ABI,
				address,
				signer
			);
			this.setState({ _provider:provider, _signer:signer, _contractZksync:contractZksync });
			console.log("provider : " + provider);
			console.log("signer : " + signer);
			console.log("contractZksync : " + contractZksync);*/

			if (provider) {


				(async () => {


					if (web3Modal.cachedProvider != "walletconnect") {

						const chainId = 1;

						if (window.ethereum.networkVersion !== chainId) {
							try {
								await window.ethereum.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await window.ethereum.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												chainName: 'Ethereum Mainnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
													rpcUrls: ['https://etherscan.io'],

													/*chainName: 'Goerli Test Network',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Goerli Test Network', decimals: 18, symbol: 'GoerliETH' },
													rpcUrls: ['https://goerli.infura.io/v3/'],*/
											},
										],
									});
								}
							}
						}





						try {

							try {
								localStorage.setItem('isWalletConnected', true);
							} catch (ex) {
								console.log(ex)
							}

							owner = await contract.methods.owner().call();
							this.setState({ _owner: owner });

							endTime = await contract.methods.endTime().call();
							this.setState({ _endTime: endTime });
							console.log("endTime : " + endTime);

							feePercentage = await contract.methods.feePercentage().call();
							this.setState({ _feePercentage: feePercentage });
							console.log("feePercentage : " + feePercentage);

							const balanceC = await web3.eth.getBalance(address);
							poolBalance = balanceC * 1 * (100 - feePercentage) / 100000000000000000000;;
							//console.log('Contract Balance:', web3.utils.fromWei(poolBalance, 'ether'), 'BNB');
							console.log('Contract Balance:', poolBalance);
							console.log('balanceC:', balanceC);


							if (isNaN(poolBalance)) {
								console.log('Calculation resulted in NaN.');
								poolBalance = 0;
							}

							this.setState({ _poolBalance: poolBalance });
							this.setState({ endTime: new Date(endTime * 1000) });
							this.interval = setInterval(this.updateCountdown, 1000);


							ticketCount = await contract.methods.ticketCount().call();
							this.setState({ _ticketCount: ticketCount });
							console.log("ticketCount : " + ticketCount);

							minimumTicketCount = await contract.methods.minimumTicketCount().call();
							this.setState({ _minimumTicketCount: minimumTicketCount });
							console.log("minimumTicketCount : " + minimumTicketCount);

							lotteryIndex = await contract.methods.lotteryIndex().call();
							this.setState({ _lotteryIndex: lotteryIndex });
							console.log("lotteryIndex : " + lotteryIndex);

							let y = 0;
							let limit = lotteryIndex;
							for (let r = limit; r > 0; r--) {
								records[y] = await contract.methods.records(r - 1).call();
								marqueeA[y] = [records[y].walletAddress, records[y].prize];
								this.setState({ _marqueeA: marqueeA });
								console.log("records : " + records);
								y++;
							}

							drawCount = await contract.methods.drawCount().call();
							this.setState({ _drawCount: drawCount });
							console.log("drawCount : " + drawCount);

							ticketPrice = await contract.methods.ticketPrice().call();
							this.setState({ _ticketPrice: ticketPrice });
							console.log("ticketPrice : " + ticketPrice);

							winningTicketNumber = await contract.methods.winningTicketNumber().call();
							this.setState({ _winningTicketNumber: winningTicketNumber });
							console.log("winningTicketNumber : " + winningTicketNumber);

							myTicketCount = await contract.methods.myTicketCount(account, lotteryIndex).call();
							this.setState({ _myTicketCount: myTicketCount });
							console.log("myTicketCount : " + myTicketCount);

							maximumTicketCount = await contract.methods.maximumTicketCount().call();
							this.setState({ _maximumTicketCount: maximumTicketCount });
							console.log("maximumTicketCount : " + maximumTicketCount);

							TicketsRemaining = maximumTicketCount - ticketCount;
							this.setState({ _TicketsRemaining: TicketsRemaining });

							contract.methods.getRecords().call().then(records => {
								console.log('Records:', records);
							})
								.catch(error => {
									console.error('Error:', error);
								});

							for (let x = 0; x < 0; x++) {
								myTickets = await contract.methods.myTickets(account, lotteryIndex,).call();
								this.setState({ _myTickets: myTickets });
								console.log("myTickets : " + myTickets);
							}

						} catch (err) {
							console.log("err: " + err);

						}

					} else {

						const chainId = 1;

						if (WalletConnectProvider.networkVersion !== chainId) {
							try {
								await WalletConnectProvider.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await window.ethereum.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												chainName: 'Ethereum Mainnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
													rpcUrls: ['https://etherscan.io'],

													/*chainName: 'Goerli Test Network',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Goerli Test Network', decimals: 18, symbol: 'GoerliETH' },
													rpcUrls: ['https://goerli.infura.io/v3/'],*/
											},
										],
									});
								}
							}
						}


						try {

							try {
								localStorage.setItem('isWalletConnected', true);
							} catch (ex) {
								console.log(ex)
							}

							owner = await contract.methods.owner().call();
							this.setState({ _owner: owner });

							endTime = await contract.methods.endTime().call(); endTime = await contract.methods.endTime().call();
							this.setState({ _endTime: endTime });
							console.log("endTime : " + endTime);

							feePercentage = await contract.methods.feePercentage().call();
							this.setState({ _feePercentage: feePercentage });
							console.log("feePercentage : " + feePercentage);

							const balanceC = await web3.eth.getBalance(address);
							poolBalance = balanceC * 1 * 1 * (100 - feePercentage) / 100000000000000000000;;
							//console.log('Contract Balance:', web3.utils.fromWei(poolBalance, 'ether'), 'BNB');
							console.log('Contract Balance:', poolBalance);
							console.log('balanceC:', balanceC);

							if (isNaN(poolBalance)) {
								console.log('Calculation resulted in NaN.');
								poolBalance = 0;
							}

							this.setState({ _poolBalance: poolBalance });

							this.setState({ endTime: new Date(endTime * 1000) });

							this.interval = setInterval(this.updateCountdown, 1000);



							ticketCount = await contract.methods.ticketCount().call();
							this.setState({ _ticketCount: ticketCount });
							console.log("ticketCount : " + ticketCount);

							minimumTicketCount = await contract.methods.minimumTicketCount().call();
							this.setState({ _minimumTicketCount: minimumTicketCount });
							console.log("minimumTicketCount : " + minimumTicketCount);

							lotteryIndex = await contract.methods.lotteryIndex().call();
							this.setState({ _lotteryIndex: lotteryIndex });
							console.log("lotteryIndex : " + lotteryIndex);

							let y = 0;
							let limit = lotteryIndex;
							for (let r = limit; r > 0; r--) {
								records[y] = await contract.methods.records(r - 1).call();
								marqueeA[y] = [records[y].walletAddress, records[y].prize];
								this.setState({ _marqueeA: marqueeA });
								console.log("records : " + records);
								y++;
							}

							drawCount = await contract.methods.drawCount().call();
							this.setState({ _drawCount: drawCount });
							console.log("drawCount : " + drawCount);

							ticketPrice = await contract.methods.ticketPrice().call();
							this.setState({ _ticketPrice: ticketPrice });
							console.log("ticketPrice : " + ticketPrice);

							winningTicketNumber = await contract.methods.winningTicketNumber().call();
							this.setState({ _winningTicketNumber: winningTicketNumber });
							console.log("winningTicketNumber : " + winningTicketNumber);

							myTicketCount = await contract.methods.myTicketCount(account, lotteryIndex).call();
							this.setState({ _myTicketCount: myTicketCount });
							console.log("myTicketCount : " + myTicketCount);

							maximumTicketCount = await contract.methods.maximumTicketCount().call();
							this.setState({ _maximumTicketCount: maximumTicketCount });
							console.log("maximumTicketCount : " + maximumTicketCount);

							TicketsRemaining = maximumTicketCount - ticketCount;
							this.setState({ _TicketsRemaining: TicketsRemaining });

							for (let x = 0; x < 0; x++) {
								myTickets = await contract.methods.myTickets(account, lotteryIndex,).call();
								this.setState({ _myTickets: myTickets });
								console.log("myTickets : " + myTickets);
							}

						} catch (err) {
							console.log("err: " + err);

						}
					}
				})();

				//.....................................................................//

				// Legacy providers may only have ethereum.sendAsync
				const chainId = await provider.request({
					method: 'eth_chainId'
				})

			} else {

				// if the provider is not detected, detectEthereumProvider resolves to null
				console.error('Please install a Valid Wallet');
				alert('A valid provider could not be found!');

			}

		}

	}

	walletDisconnect = async event => {
		event.preventDefault();


		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "bf933c3446b3464c988114813a1360ac" // required
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});



		// disconnect wallet
		web3Modal.clearCachedProvider();
		window.location.reload(true);

		try {
			localStorage.setItem('isWalletConnected', false)
		} catch (ex) {
			console.log(ex)
		}

	}

	navbarOpen = async event => {
		event.preventDefault();

		this.setState({ _navbarOpen: 1 });
	}

	mintBox = async event => {
		event.preventDefault();

		this.setState({ myNFTWallet: 0 });
	}

	closeWindow = async event => {
		event.preventDefault();
		this.setState({ statusError: false });
		this.setState({ statusLoading: false });
		this.setState({ success: false });
	}

	async displayRecordsInDiv() {
		try {
			// Call the getRecords() function
			const records = await contract.methods.getRecords().call();

			// Get the <div> element to display the records
			const recordsDiv = document.getElementById('recordsDiv');

			// Create an array and populate it with records information
			const recordsArray = records.map(record => ({
				drawNumber: record.drawNumber,
				winner: record.walletAddress,
				prize: `${record.prize} ETH`,
			}));

			// Create a <pre> element to display the array as formatted text
			const pre = document.createElement('pre');
			pre.textContent = JSON.stringify(recordsArray, null, 2);

			// Append the <pre> element to the <div>
			recordsDiv.appendChild(pre);
		} catch (error) {
			console.error('Error:', error);
		}
	}

	updateCountdown = () => {
		const now = new Date();
		const timeLeft = Math.max(0, this.state.endTime - now);

		const hours = Math.floor(timeLeft / (1000 * 60 * 60));
		const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
		const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

		this.setState({
			hours: hours < 10 ? '0' + hours : hours.toString(),
			minutes: minutes < 10 ? '0' + minutes : minutes.toString(),
			seconds: seconds < 10 ? '0' + seconds : seconds.toString()
		});
	}

	render() {

		return (

			<div class="allWrap">
				<div class="light">
					<div class="page-loader">
						<div class="txt">Loading</div>
						<div class="loading-area">
							<div class="loader">
								<div></div>
								<div></div>
								<div></div>
								<div></div>
								<div></div>
								<div></div>
								<div></div>
							</div>
						</div>
					</div>
					<div class="cont">
						<div class="headers">

							<div class="h1">

								<div class="logoDiv"><img class="logo" onClick={this.walletDisconnect} src={logo} /></div>

								<div class="right">
									
									{this.state.walletAddress === '' ?
										(<form class="connect2">
											<button onClick={this.walletConnect} class="connectBtn" >WALLET CONNECT</button>
										</form>) : (<form class="connect2" onClick={this.walletDisconnect}><button class="connectBtn" >
											{this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}</button></form>)}

								</div>

							</div>

						</div>

						{this.state.walletAddress === '' ?
							(<div >
								<div class="introduction">
									<div class="title">Blockchain Lottery</div>
									<div class="subH anim-typewriter">Secure, Transparent, and Thrilling!</div>
								</div>

								<button class="connectButton" onClick={this.walletConnect}>ENTER NOW</button></div>) :

							(<div class="boxWrap2MintMain">
								<div>



									{this.state._marqueeA.map((record) => {

										return (

											<div class="ticker-wrap">
												<div class="ticker">

													<div class="ticker__item"><bold class="boldC">Blockchain Lottery Winner's List =></bold></div>
													<div class="ticker__item"><span class="HL">User : {record[0].slice(0, 3) + "..." + record[0].slice(39, 42)}</span></div>
													<div class="ticker__item"><span class="HL2">Prize Won : {record[1] / 1000000000000000000} ETH</span></div>
												</div>

											</div>
										);
									})}

								</div>

								<div class="boxWrap2Mint">

									<div class="box1">
										<div class="subBoxMain">
											<div class="boxH">The Next Draw</div>
											<div class="subBoxMain2">
												<div class="subBox">
													<div class="subBoxCon1">Total Pool</div>
													<div class="subBoxCon2">{this.state._poolBalance} ETH</div>
												</div>

												<div class="subBox">
													<div class="subBoxCon1">Tickets Remaining</div>
													<div class="subBoxCon2">{this.state._TicketsRemaining}</div>
												</div>
											</div>

											<div class="timeMain">

												<div class="timeMain2">
													<div class="time">{this.state.hours}</div>
													<div class="timeN">HOURS</div>
												</div>

												<div class="timeMain2">
													<div class="time">{this.state.minutes}</div>
													<div class="timeN">MINUTES</div>
												</div>

												<div class="timeMain2">
													<div class="time">{this.state.seconds}</div>
													<div class="timeN">SECONDS</div>
												</div>
											</div>
										</div>
									</div>

									<div class="box1">
										<div class="subBoxMain">
											<div class="boxH2">
												<div class="txtH2">Price Per Ticket</div>
												<div class="txtH2">{this.state._ticketPrice / 1000000000000000000} ETH</div>
											</div>
											<div>
												<div class="subBox2">
													<div class="minting_count_button">
														<div class="center">
															<button onClick={this.onSubmitMinus} class="btnfos-0-2">-</button>
														</div>

														<div>
															<div class="nftminter2"><span class="nftamount">{this.state.nftMintingAmount}</span></div>
														</div>

														<div class="center">
															<button onClick={this.onSubmitPlus} class="btnfos-0-2">+</button>
														</div>
													</div>
												</div>

												<div class="subBox3">
													<div class="subBoxCon2-1">My Tickets</div>
													<div class="subBoxCon2-1">{this.state._myTicketCount}</div>
												</div>

												<div class="subBox3">
													<div class="subBoxCon2-1">Total cost of tickets</div>
													<div class="subBoxCon2-1">{this.state._totalTicketPrice / 1000000000000000000} ETH</div>
												</div>

												<div class="subBox3">
													<div class="subBoxCon1">Service fees</div>
													<div class="subBoxCon1">{this.state._fee / 1000000000000000000} ETH</div>
												</div>

												{this.state.hours === 0 && this.state.minutes === 0 && this.state.seconds === 0 ?
													(<button class="connectButton2" disabled><span class="disable">Next Round is Opening Soon</span></button>) :
													(<button onClick={this.buyTickets} class="connectButton2">Buy {this.state.nftMintingAmount} tickets for {this.state._totalTicketPrice / 1000000000000000000} ETH</button>)}
											</div>
										</div>
									</div>

									<div>

										{this.state.statusError ?
											(<div class="formMain2">
												<div class="formMain-2">

													<div>
														<div class="errorMessage">
															<div >Sorry, something went wrong please try again later</div>
														</div>
													</div>
												</div>

											</div>) : null}

										{this.state.statusLoading ?
											(<div class="formMain2">
												<div class="formMain-2">

													<div class="loadingContainer">
														<div>
															<div class="lds-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
														</div>
													</div>

												</div>

											</div>) : null}

										{this.state.success ?
											(<div class="formMain2">
												<div class="formMain-2">

													<div>
														<img class="check" src={check} />

														<div class="success-message">
															<h2>Payment Successful!</h2>
															<p>Ticket Purchase Completed</p>
														</div>
													</div>
												</div>

											</div>) : null}

									</div>

								</div>

								{this.state.walletAddress !== '' && this.state._owner === this.state.walletAddress ?

									(<div class="adminPanel">

										<div class="faqTitle">ADMIN PANEL</div>

										<div class="accordin reveal">

											<details data-aos="fade-up" data-aos-duration="1000">
												<summary>Start Project</summary>
												<div class="faq__content">
													<p><button class="submit" onClick={this.startBuying}>Write</button></p>
												</div>
											</details>
											<details data-aos="fade-up" data-aos-duration="1000">
												<summary>Draw Lottery</summary>
												<div class="faq__content">
													<p><button onClick={this.drawLottery} class="submit">Write</button></p>
												</div>
											</details>

											<details data-aos="fade-up" data-aos-duration="1000">
												<summary>Change End Time</summary>
												<div class="faq__content">
													<form onSubmit={this.changeEndTime}>
														<p><input value={this.state._changeEndTime}
															onChange={event => this.setState({ _changeEndTime: event.target.value })} placeholder='set end time' required type="number"></input></p>
														<p><button type='submit' class="submit">Write</button></p>
													</form>
												</div>
											</details>

											<details data-aos="fade-up" data-aos-duration="1000">
												<summary>Change Duration</summary>
												<div class="faq__content">
													<form onSubmit={this.changeDuration}>
														<p><input value={this.state._changeDuration}
															onChange={event => this.setState({ _changeDuration: event.target.value })} placeholder='set duration' type="number" required></input></p>
														<p><button type='submit' class="submit">Write</button></p>
													</form>
												</div>
											</details>
										</div>
									</div>) : null}

								<Footer />

							</div>)}

					</div>

				</div>

			</div >)
	}
}

export default App;
