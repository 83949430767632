import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import os from '../assets/os.png';
import twitter from '../assets/twitter.png';
import discord from '../assets/discord.png';
 
const mintsquare = () => {
    window.open("https://mintsquare.io/collection/zksync/0x0636fbd5a3f93b76bae3d177157d217441698783");
}

const tweet = () => {
    window.open("https://twitter.com/zksync_doge");
}

const tg = () => {
    window.open("#");
}

const element = () => {
    window.open("https://element.market/collections/zksyncdoge?search%5Btoggles%5D[0]=BUY_NOW");
}

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}


class Footer extends Component {


    render() {
        return (
  
                <div class="footer1">

                    <div class="line2"></div>

                    <div class="footer">
                        <div class="copyright">@2023 Blockchain Lottery. All rights reserved </div>
                        <div class="terms">
                            <div>Privacy Policy</div>
                        </div>
                    </div>
                </div>
         )
    }
}

export default Footer;

